<template>
  <div>
    <main class="bg-green-800">
      <slot />
    </main>
  </div>
</template>

<script setup lang="ts">
const metaStore = useMetaStore();
const pageTitle = computed(() => metaStore.pageTitle);
const metaTitle = computed(() => metaStore.metaTitle ?? pageTitle);
const description = computed(() => metaStore.metaDescription);
const route = useRoute();

useHead({
  title: pageTitle,
  link: [
    { rel: "icon", type: "image/svg+xml", href: "/img/logos/favicon.svg" },
    {
      rel: "canonical",
      href: `https://www.aussiebroadband.com.au${route.path}`,
    },
  ],
  meta: [{ name: "theme-color", content: "#13322B" }],
});

useServerSeoMeta({
  title: metaTitle,
  ogTitle: metaTitle,
  description: description,
  ogDescription: description,
  ogImage:
    "https://assets.aussiebroadband.com.au/web/images/misc/abb-og-image.png",
  twitterTitle: metaTitle,
  twitterDescription: description,
  twitterImage:
    "https://assets.aussiebroadband.com.au/web/images/misc/abb-og-image.png",
  twitterSite: "@Aussie_BB",
});
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: opacity 0.3s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>
